import React, { useEffect, useState } from 'react'
import { createWorker } from 'tesseract.js'
import './App.css'

function App() {

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  }

  const worker = createWorker({
    logger: m => console.log(m),
  })
  const doOCR = async () => {
    const img = await getBase64FromUrl(`https://www.1developplus.com/a9d24a77ade3a8a2d9b18ee0d42ddc39d17832bdbf89508056b153b4bef0b6cd.jpg`)
    console.log(img)
    await worker.load()
    await worker.loadLanguage('tha')
    await worker.initialize('tha')
    const { data: { text } } = await worker.recognize(img)
    setOcr(text)
  }
  const [ocr, setOcr] = useState('Recognizing...')
  useEffect(() => {
    doOCR()
  })
  return (
    <div className="App">
      <p>{ocr}</p>
    </div>
  )
}

export default App
